import React from 'react'

const Footer = () => {
  return (
    <footer>
      <div>
        <p>&copy; <a href="https://terra0.org" rel="external nofollow noopener noreferrer" target="_blank">terra0</a></p>
        <p>Flowertoken converter v2021</p>
      </div>
    </footer>
  )
}

export default Footer
