import React, { useEffect, useState } from 'react'
import Generateentry from './Generateentry'
import _ from 'lodash';

const Wallet = (props) => {
  const [legacyTokens, setLegacyTokens] = useState()
  const [convertedTokens, setConvertedTokens] = useState();

  console.log(props)

  useEffect(() => {
    setLegacyTokens(props.web3state.legacyToken)
    setConvertedTokens( _.sortBy(props.web3state.newTokens, 'tokenId'))
  }, [props.web3state.legacyToken, props.web3state.newTokens]);

  return (
    <section className="wallet">
      <h2>Instructions</h2>
      <p>Here you can convert your legacy Flowertokens to the new/updated OpenSea-compatible ERC721 non-fungible token format.</p>
      <ol>
        <li>Your legacy token must not be listed for sale. If it is for sale, the <code>stop_sell</code> transaction needs to be called first before continuing.</li>
        <li>Then, the new/updated smart contract needs the permission to convert your legacy token via the <code>permit</code> transaction.</li>
        <li>Your legacy token needs to be converted via the <code>convert</code> transaction. Afterwards you can continue to OpenSea.</li>
      </ol>
      <p><strong>Note:</strong> please don’t disconnect your wallet connection while the <code>pending</code> spinner is shown; else the pending state cannot be displayed anymore and would need to be manually checked on Etherscan.</p>
      <h2>Collection of my original Flowertokens</h2>
      {props.web3state.fetching ?
        <div className="loading">
          <span className="spinner"></span>
        </div>
        : props.web3state.signer ?
          <React.Fragment>
            <ul className="tokens">
              {legacyTokens?.map((listitem, i) => (
                <li key={listitem.tokenId}>
                  <Generateentry
                    item={listitem}
                    index={i}
                    web3state={props.web3state}
                    getTokenState={props.getTokenState}
                    handlerpending={props.handlerpending}
                    moveFromLegacyToNew={props.moveFromLegacyToNew} />
                </li>
              ))}
            </ul>
            <h2>Collection of my wrapped Flowertokens</h2>
            <ul className="tokens">
              {convertedTokens?.map(item => (
                <li key={item.tokenId}>
                  <div>
                    <span>Token #{item.tokenId}</span>
                  </div>
                    <a href={`https://opensea.io/assets/${props.web3state.newContract.address}/${item.tokenId}`} rel="external nofollow noopener noreferrer" target="_blank">OpenSea</a>
                </li>
              ))}
            </ul>
            <p><strong>Note:</strong> it might take some minutes until the token is shown on OpenSea.</p>
          </React.Fragment>
        : <p>Please connect your wallet … &#x1F331;&#x1F331;&#x1F331;</p>
      }
    </section>
  )
}
export default Wallet
