import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import _ from 'lodash';

import './assets/css/index.css'

import Header from './components/header'
import Footer from './components/footer'

import Landing from './routes/landing'
import Wallet from './routes/wallet'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.handlelogin= this.handlelogin.bind(this);
    this.handlerstartpending = this.handlerstartpending.bind(this)
    this.findposition = this.findposition.bind(this)
    this.getstateoflegacytoken = this.getstateoflegacytoken.bind(this)
    this.moveFromLegacyToNew = this.moveFromLegacyToNew.bind(this)

    this.state = {
      fetching: false, // fetch state to prevent waterfall in frontend
      signer: null,
      newContract: null,
      legacyContract: null,
      legacyToken:[],
      newTokens: []
    };
  }

    async getstateoflegacytoken(tokenId, index) {
      var onsale = await this.state.legacyContract.get_token_data_buyable(tokenId)
      var permission = await this.state.legacyContract.getApproved(tokenId)
      var permission_bool = false

      if (permission == this.state.newContract.address) {
        permission_bool = true
      }

      var statetopush = {
        "tokenId": tokenId,
        "onsale": onsale,
        "permission": permission_bool,
        "pendingtransaction": false,
      }

      if (index) {
        const updatedTokens = this.state.legacyToken.filter((_, i) => i !== parseInt(index)).concat(statetopush)
        this.setState({ legacyToken: _.sortBy(updatedTokens, 'tokenId') })
      } else {
        this.setState({ legacyToken: this.state.legacyToken.concat(statetopush) })
      }
    }

  async getlegacytokens() {
    this.setState({ legacyToken: [] })

    var address = await this.state.signer.getAddress()
    var getmytokens = await this.state.legacyContract.get_my_tokens()

    for (var tokennumber = 1 ; tokennumber < 101; tokennumber ++) {
      if(getmytokens[tokennumber] == true) {
        var owneraddress = await this.state.legacyContract.ownerOf(tokennumber)
        if (owneraddress === address) {
          await this.getstateoflegacytoken(tokennumber)
        }
      }
    }
    console.log(this.state.legacyToken)
  }

  async getConvertedTokens() {
    var address = await this.state.signer.getAddress()
    console.log(address)
    for (var tokennumber = 1 ; tokennumber < 101; tokennumber ++) {
      try {
         var owneraddress = await this.state.newContract.ownerOf(tokennumber)
        if (owneraddress === address) {
          var statetopush = {
          "tokenId": tokennumber,
         /* Leaving these here in case we need them at some point.
          "onsale": onsale,
          "permission": permission_bool,
          "pendingtransaction": false,
          */
        }
           this.setState({newTokens: this.state.newTokens.concat(statetopush)})
        }
      } catch (error) {
        console.log("token not minted")
      }
    }

    // once we went through all new tokens we set fetching to false and display all tokens
    this.setState({ fetching: false})
  }

  moveFromLegacyToNew(index) {
    // copy token by index and add to newTokens
    let item = { ...this.state.legacyToken[index] }

    this.setState({ newTokens: this.state.newTokens.concat(item) })

    // delete converted token from legacy array
    const updatedTokens = this.state.legacyToken.filter((_, i) => i !== index)

    this.setState({ legacyToken: updatedTokens })
  }

  findposition(tokenId){
    var lenght = this.state.legacyToken.lenght

    for (var i = 0;i< lenght;i++){
      console.log(this.state.legacyToken.tokenId,tokenId)
      if (this.state.legacyToken[i].tokenId == tokenId){
        return i
      }
    }
  }

  handlerstartpending(index, isPending) {
    console.log("-------------")
    console.log(index)

    let items = [...this.state.legacyToken];
    let item = { ...items[index] };

    item.pendingtransaction = isPending;
    items[index] = item;

    this.setState({legacyToken:items})
  }

  async handlelogin(signer, newContract, legacyContract) {
    this.setState({
       fetching: true, // setting fetching to true to display loading spinner
       signer:signer,
       newContract:newContract,
       legacyContract:legacyContract
     })

     this.getlegacytokens()
     this.getConvertedTokens()
   }

render() {
  return (
    <>
      <BrowserRouter>
        <Header handlelogin={this.handlelogin} signer={this.state.signer}/>
        <main>
          <Switch>
          <Route path='/' exact component={Landing} />
          <Route path='/wallet' render={(props) => (
            <Wallet {...props}
              web3state={this.state}
              handlerpending={this.handlerstartpending}
              getTokenState={this.getstateoflegacytoken}
              moveFromLegacyToNew={this.moveFromLegacyToNew}
            />
          )}
          />
          </Switch>
        </main>
        <Footer />
      </BrowserRouter>
    </>
  )
}
}

export default App
