import React, { useState } from 'react'

const Generateentry = ({ item, index, web3state, getTokenState, handlerpending, moveFromLegacyToNew }) => {
  const [loading, setLoading] = useState(false);

  console.log(item)

  const handlePermission = async () => {
    setLoading(true)

    try {
      const permit = await web3state.legacyContract.approve(web3state.newContract.address, item.tokenId)
      handlerpending(index)
      await permit.wait()
      getTokenState(item.tokenId, index.toString())
    }
    catch (err) {
      console.log(err)
    } finally {
      handlerpending(index, false)
      setLoading(false)
    }
  }

  const stop_sell_wrapper = async () => {
    console.log(item.tokenId)

    setLoading(true)

    try {
      let idasint = parseInt(item.tokenId)

      console.log(idasint)

      handlerpending(index, true)

      let re = await web3state.legacyContract.stop_sell(idasint)

      console.log(await re.wait())

      getTokenState(item.tokenId, index.toString())
    } catch (e) {
      console.log(e)
    } finally {
      handlerpending(index, false)
      setLoading(false)
    }
  }

  const convert = async () => {
    setLoading(true)

    console.log(web3state)

    try {
      let convert = await web3state.newContract.convertToNew(item.tokenId)

      handlerpending(index, true)

      await convert.wait()
      await moveFromLegacyToNew(index)
    } catch (err) {
      console.log(err)
    } finally {
      handlerpending(index, false)
    }

    setLoading(false)
  }

  if (item.pendingtransaction === true) {
    return (
      <React.Fragment>
        <div>
          <span>Token #{item.tokenId}</span>
          <span className="dim"> (legacy)</span>
        </div>
        <div className="loading">
          <span className="spinner"></span>
        </div>
      </React.Fragment>
    )
  } else if (item.permission === true) {
    return (
      <React.Fragment>
        <div>
          <span>Token #{item.tokenId}</span>
          <span className="dim"> (legacy)</span>
        </div>
        {loading ?
          <div className="loading">
            <span className="spinner"></span>
          </div>
          :
          <button onClick={convert}>convert</button>
        }
      </React.Fragment>
    )
  } else if (item.onsale === true) {
    return (
      <React.Fragment>
        <div>
          <span>Token #{item.tokenId}</span>
          <span className="dim"> (legacy)</span>
        </div>
        {loading ?
          <div className="loading">
            <span className="spinner"></span>
          </div>
          :
          <button onClick={stop_sell_wrapper}>stop_sell</button>
        }
      </React.Fragment>
    )
  } else if (item.permission === false) {
    return (
      <React.Fragment>
        <div>
          <span>Token #{item.tokenId}</span>
          <span className="dim"> (legacy)</span>
        </div>
        {loading ?
          <div className="loading">
            <span className="spinner"></span>
          </div> :
          <button onClick={handlePermission}>permit</button>
        }
      </React.Fragment>
    )
  }

  return null
}

export default Generateentry
