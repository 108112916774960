import React from 'react'
import { Link } from 'react-router-dom'

import Web3Modal from 'web3modal'
import FlowerTokenARTEFACT from '../../assets/data/FlowerToken_Mainnet.json'
import LegacyTokenARTEFACT from '../../assets/data/buyable.json'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { ethers } from 'ethers'

const Header = (props) => {
  const legacyContractabi = LegacyTokenARTEFACT['abi']
  const legacyContractaddress = "0x43C9B7B7cE699aC2c1D3aaD3B5A78274A0f9C86d"

  const newContractabi = FlowerTokenARTEFACT['abi']
  const newContractaddress = FlowerTokenARTEFACT['networks']['1']['address']

  let provider
  let web3provider

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: '0a70d112c1774819b240aea28f4219c5'
      }
    }
  }

  const web3Modal = new Web3Modal({
    cacheProvider: false, // optional
    providerOptions // required
  })

  const buildcontract = async(contractaddress, contractabi, signer) => {
    const tcontract = new ethers.Contract(contractaddress, contractabi , signer )
    return tcontract
  }

  const onConnect = async() => {
    console.log('Opening a dialog', web3Modal)

    try {
      provider = await web3Modal.connect()
      web3provider = new ethers.providers.Web3Provider(provider)
      console.log(web3provider);

      const newSigner = web3provider.getSigner()
      const legacyContract = await buildcontract(legacyContractaddress,legacyContractabi,newSigner)
      const newContract = await buildcontract(newContractaddress,newContractabi,newSigner)

      props.handlelogin(newSigner,newContract,legacyContract)
    } catch(e) {
      console.log('Could not get a wallet connection', e)
      return
    }
  }

  return (
    <React.Fragment>
      <header>
        <nav>
          <Link to='/'>
            <h1>Flowertokens</h1>
          </Link>
          <span>
            <Link to='/wallet' activeclassname='active'>
              Wallet
            </Link>
          </span>
          <button onClick={onConnect} disabled={props.signer} title={props.signer ? 'connected to wallet' : 'not connected to wallet'}>
            <span>●</span>
            <span>{props.signer  ? 'connected' : 'connect'}</span>
          </button>
        </nav>
      </header>
    </React.Fragment>
  )
}

export default Header
