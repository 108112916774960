import React from 'react'

import img_01 from '../../assets/img/flowertokens-01.jpg';
import img_02 from '../../assets/img/flowertokens-02.jpg';

const Landing = () => {
  return (
    <section className="about">
      <h2>Flowertokens</h2>
      <p><a href="https://terra0.medium.com/everything-you-wanted-to-know-about-flowertokens-but-were-afraid-to-ask-part-1-general-concept-ea50427b718b" rel="external nofollow noopener noreferrer" target="_blank">Flowertokens</a> was an experimental project centered around the tokenization and verification of natural commodities, and an early attempt at creating a combined crypto-collectible physical asset.</p>
    <p>The experiment came to an end was <a href="https://flowertokens-archive.terra0.org" rel="external nofollow noopener noreferrer" target="_blank">archived</a> in November 2018.</p>
      <figure>
        <img src={img_01} alt="the Flowertoken rack housing 100 seedlings, accompanied by one person from the terra0 team standing on a ladder, showing presenting the terra0 logo on the back of their overall to the camera and viewer" />
      </figure>
      <p>You could buy, trade, and speculate on tokenized <em>Dahlia &times; Hortensis</em> seedlings via an online marketplace. The state of the individual tokens were automatically updated according to the different phases of their corresponding plant’s growth.</p>
      <figure>
        <img src={img_02} alt="close-up of five already grown seedlings in the Flowertoken rack, each of them with a different height" />
      </figure>
      <p>Flowertokens marked the beginning of a series of projects oriented around these themes of terra0&shy;&mdash;&shy;a group of developers and researchers involved in the planning and creation of hybrid ecosystems.</p>
      <h2>Read more about the project!</h2>
      <p>On our <a href="https://terra0.medium.com/" rel="external nofollow noopener noreferrer" target="_blank">Medium weblog</a> you can find in-depth technical articles, focused on specific aspects of the original Flowertokens project.</p>
      <ul>
        <li>
          <a href="https://terra0.medium.com/everything-you-wanted-to-know-about-flowertokens-but-were-afraid-to-ask-part-1-general-concept-ea50427b718b" rel="external nofollow noopener noreferrer" target="_blank">Part 1: General concept</a>
        </li>
        <li>
          <a href="https://terra0.medium.com/everything-you-wanted-to-know-about-flowertokens-but-were-afraid-to-ask-part-2-physical-12e9fc611b7" rel="external nofollow noopener noreferrer" target="_blank">Part 2: Physical Infrastructure</a>
        </li>
        <li>
          <a href="https://terra0.medium.com/everything-you-wanted-to-know-about-flowertokens-but-were-afraid-to-ask-part-3-the-oracle-and-c301091b19d1" rel="external nofollow noopener noreferrer" target="_blank">Part 3: Oracle + Tokenization</a>
        </li>
        <li>
          <a href="https://terra0.medium.com/everything-you-wanted-to-know-about-flowertokens-but-were-afraid-to-ask-part-4-blooming-and-96e9de024767" rel="external nofollow noopener noreferrer" target="_blank">Part 4: Blooming + Infrastructure</a>
        </li>
        <li>
          <a href="https://terra0.medium.com/when-bloom-the-end-of-flowertokens-project-archiving-and-the-end-of-trading-47d5bf1d379a" rel="external nofollow noopener noreferrer" target="_blank">“when bloom” – The End</a>
        </li>
      </ul>
    </section>
  )
}

export default Landing
